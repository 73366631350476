<div class="sidemenu" role="navigation">
    <div class="inner-sidemenu">
        <div class="logo">
            <a routerLink="/home"><img alt="logo" src="{{ config.app.assets.logoMediumLight }}" /></a>
        </div>
        <div class="panel-group" id="accordion">
            <!-- Banner -->
            <div class="panel">
                <a data-toggle="collapse" data-parent="#accordion" href="#menu1" class="arbol1b">Banners</a>
                <div id="menu1" class="panel-collapse collapse">
                    <a [routerLink]="['/banners']" class="arbol2">Lista de banners</a>
                    <a [routerLink]="['/banners/new']" class="arbol2">Nuevo banner</a>
                    <a [routerLink]="['/banners/paginas']" class="arbol2">Lista de paginas</a>
                    <a [routerLink]="['/banners/paginas/new']" class="arbol2">Nueva pagina</a>
                    <a [routerLink]="['/banners/zonas']" class="arbol2">Lista de zonas</a>
                    <a [routerLink]="['/banners/zonas/new']" class="arbol2">Nueva zona</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu2" class="arbol1b">
                    Instrumentos y evaluaciones
                </a>
                <div id="menu2" class="panel-collapse collapse">
                    <a [routerLink]="['/visualizador_buscador']" class="arbol2">Buscar Evaluaciones e Instrumentos</a>
                    <a [routerLink]="['/instrumentos']" class="arbol2">Mis Instrumentos</a>
                    <a [routerLink]="['/instrumentos/generar/validado']" class="arbol2">Generar Instrumento Validado</a>
                    <a [routerLink]="['/instrumentos/generar/porlote']" class="arbol2">Generar desde Lote</a>
                    <a [routerLink]="['/instrumento_predefinidos']" class="arbol2">Predefinidos</a>
                    <a [routerLink]="['/evaluacion_sugeridas']" class="arbol2">Evaluaciones Recomendadas</a>
                    <a [routerLink]="['/instrumentos/mensuales']" class="arbol2">Ensayos Mensuales</a>
                    <a [routerLink]="['/evaluaciones_masivas']" class="arbol2">Evaluaciones masivas</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu3" class="arbol1b">Nóminas</a>
                <div id="menu3" class="panel-collapse collapse">
                    <a [routerLink]="['/nominas/alumnos']" class="arbol2">Cargar Nómina Alumnos</a>
                    <a [routerLink]="['/nominas/profesores']" class="arbol2">Cargar Nómina Profesores</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu4" class="arbol1b">Planes de Estudio</a>
                <div id="menu4" class="panel-collapse collapse">
                    <a [routerLink]="['/plan_estudios']" class="arbol2">Lista de planes</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu5" class="arbol1b">Establecimientos</a>
                <div id="menu5" class="panel-collapse collapse">
                    <a [routerLink]="['/establecimientos']" class="arbol2">Lista de establecimientos</a>
                    <a [routerLink]="['/establecimientos']" [queryParams]="{ mios: 1 }" class="arbol2">
                        Mis establecimientos
                    </a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu10" class="arbol1b">Estadisticas</a>
                <div id="menu10" class="panel-collapse collapse">
                    <a
                        *ngIf="asignaturasByEvaluacionTipo$ | async as asignaturasByEvaluacionTipo"
                        [routerLink]="['/estadisticas/declarados']"
                        [queryParams]="{ asignatura_id: asignaturasByEvaluacionTipo[evaluacionTipo][0].id }"
                        class="arbol2"
                    >
                        Alumnos declarados
                    </a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu6" class="arbol1b">Asociaciones</a>
                <div id="menu6" class="panel-collapse collapse">
                    <a [routerLink]="['/asociaciones']" class="arbol2">Lista de asociaciones</a>
                    <a [routerLink]="['/asociaciones']" [queryParams]="{ mios: 1 }" class="arbol2">Mis asociaciones</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu7" class="arbol1b">Cuentas</a>
                <div id="menu7" class="panel-collapse collapse">
                    <a [routerLink]="['/cuentas']" class="arbol2">Cuentas Usuarios</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu8" class="arbol1b">Noticias</a>
                <div id="menu8" class="panel-collapse collapse">
                    <a [routerLink]="['/noticias']" class="arbol2">Lista de Noticias</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu9" class="arbol1b">Streaming</a>
                <div id="menu9" class="panel-collapse collapse">
                    <a [routerLink]="['/streaming']" class="arbol2">Canales de Streaming</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu11" class="arbol1b">Planificaciones</a>
                <div id="menu11" class="panel-collapse collapse">
                    <a [routerLink]="['/planificaciones']" class="arbol2">Lista de planificaciones</a>
                </div>
                <a data-toggle="collapse" data-parent="#accordion" href="#menu18" class="arbol1b">Typeforms</a>
                <div id="menu18" class="panel-collapse collapse">
                    <a [routerLink]="['/typeform_widgets']" class="arbol2">Lista de typeforms</a>
                    <a [routerLink]="['/typeform_widgets/new']" class="arbol2">Nuevo typeform</a>
                </div>
            </div>
        </div>
    </div>
</div>
